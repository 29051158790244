<template>
  <div class="index">
    <div ref="header"><Header :bg="bg"></Header></div>
    <div class="toubu">
      <div class="text">
        智慧城市方案中心
        <div class="bottom">
          为客户定制多场景的专业解决方案，满足不同客户需求
        </div>
      </div>
    </div>
    <!-- <div class="bar">
      <div class="son" v-for="(item, index) in list" :key="index">
        {{ item.name }}
        <em></em>
      </div>
    </div> -->

    <!-- <router-view></router-view> -->

    <div class="newbox">
      <div class="top">聚焦水务、政务、城市安全、智慧城市四大类</div>
      <!-- <div class="more">
        智能数据全生命周期产品管理，让科技有责任，让数据有温度
      </div> -->
      <div class="box">
        <div class="left">
          <div class="shang" @click="gotoshuiwu('/solutionCenter/shuiwu')">
            <div class="title">智慧水务解决方案</div>
            <div class="jieshao">
              构建全域感知、实时监测、精准调控、协同管理、高效应用的智慧水务解决方案
            </div>
          </div>
          <div class="xia" @click="gotoshuiwu('/solutionCenter/zhengwu')">
            <div class="title">智慧政务解决方案</div>
            <div class="jieshao">
              建立证照管理数据协同，实现管办分离打造服务到村社的不见面政务服务体系
            </div>
          </div>
        </div>
        <div class="right">
          <div class="shang" @click="gotoshuiwu('/solutionCenter/anquan')">
            <div class="title">城市安全解决方案</div>
            <div class="jieshao">
              打造智慧安全城市，构建公共安全体系，编织全方位、立体化的公共安全网
            </div>
          </div>

          <div class="xia" @click="gotoshuiwu('/solutionCenter/chengshi')">
            <div class="title">智慧城市解决方案</div>
            <div class="jieshao">
              构建一横N纵的智慧城市解决方案，用数据点亮城市生活
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>

    <el-backtop target=".index" class="gotoback" :bottom="300">
      <div class="lianxi">
        <div class="ph">
          <div class="sons">
            <div class="barss">
              <div class="top">咨询电话</div>
              <div class="bottom">133-3098-1239</div>
            </div>
            <div class="barss">
              <div class="top">技术支持</div>
              <div class="bottom">189-8005-3442</div>
            </div>
            <div class="barss">
              <div class="top">售后电话</div>
              <div class="bottom">400-8878-352</div>
            </div>
          </div>
        </div>
        <div class="wx">
          <div class="son">
            <img src="@/assets/imgs/home/gongzonghao.png" alt />
            <div class="tip">(成都汉康微信)</div>
          </div>
        </div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      list: [
        { name: "智慧水务解决方案" },
        { name: "智慧政务解决方案" },
        { name: "智慧城市操作方案" },
        { name: "城市安全检测方案" },
      ],
      bar: [
        {
          name: "数据采集层",
          text: "支持各种类型感知设备，通过4G/5G/Wifi等网络，将数据传回平台。目前平台支持的有Modbus, MQTT, CoAP, TCP等协议。对二次供水过程进行的智慧监管监控全流程、全方位、全天候的物联信息系统。",
        },
        {
          name: "智慧水务大数据平台",
          text: "提供数据融合系统、数据管理系统，针对不同业务需求提供多数据源数据抽取、治理、融合。同时也提供了数据计算分析系统、BI可视化系统，针对具体业务属性进行业务分析，辅助决策以及可视化管理。",
        },
        {
          name: "物联网总线平台",
          text: "为二次供水提供感知层的设备接入、边缘计算、设备管理、数据分析、设备认证、网络管理和安全运营等服务，帮助政府和企业建立一个安全可靠的万物互联世界。",
        },
        {
          name: "业务应用",
          text: "全面覆盖二次供水全流程，包括网格化管理，泵房监测，人员管理，设备管理，中水处理，DMA漏损等。借助GIS信息管理，将二次供水管理动、静态信息数据在一张图上集中展示，同时也可以根据各主题进行显示、集中调度，达到“可视化展现、针对性查找、全面化覆盖”的效果。",
        },
        {
          name: "运营管控",
          text: "依托大数据分析，提供从各种各样类型的数据中快速获得有价值信息的能力，提供品质督查、辅助决策、能效分析等服务。以全面资产管理为核心，以预算管理为主线，以生产经营为基础，以绩效考核为标准，规范和优化业务流程，分析供水、二次供水、中水循环利用。",
        },
      ],
      ban: [
        {
          num: require("../../assets/imgs/fangan/01.png"),
          src: require("../../assets/imgs/fangan/icon (5).png"),
          title: "感知能力",
          text: "空天地立体感知体系",
        },
        {
          num: require("../../assets/imgs/fangan/02.png"),
          src: require("../../assets/imgs/fangan/icon (1).png"),
          title: "透析能力",
          text: "基于自主研发的GIS+BIM引擎，构建数据孪生智慧水务",
        },
        {
          num: require("../../assets/imgs/fangan/03.png"),
          src: require("../../assets/imgs/fangan/icon (2).png"),
          title: "数据能力",
          text: "全业务流程的数据治理和模型构建能力",
        },
        {
          num: require("../../assets/imgs/fangan/04.png"),
          src: require("../../assets/imgs/fangan/icon (3).png"),
          title: "安全能力",
          text: "利用区块链技术将关键数据上链实现数据安全可靠",
        },
        {
          num: require("../../assets/imgs/fangan/05.png"),
          src: require("../../assets/imgs/fangan/icon (4).png"),
          title: "业务能力",
          text: "支撑水务八大业务范畴的流程优化再造",
        },
      ],
      type: 0,
      bg: null,

      timer: null,
      arr: [],
    };
  },
  methods: {
    active(i) {
      this.type = i;
    },
    up() {
      document.body.scrollTop = 0;
    },
    gotoshuiwu(path) {
      this.$router.push(path);
    },
    scroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.arr.push(scroll);
      //  console.log("scroll :>> ", scroll);
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");
          //控制导航条

          this.$refs.header.style.position = "absolute";
          this.$refs.header.style.top = "0";
          this.$refs.header.style.left = "0";
          this.$refs.header.style.zIndex = "100";
        } else {
          // console.log("往上滑");
          if (scroll > 300) {
            this.$refs.header.style.position = "fixed";
            this.$refs.header.style.top = "0";
            this.$refs.header.style.left = "0";
            this.$refs.header.style.zIndex = "100";
            this.bg = "black";
          }
        }
      }
      if (scroll <= 280) {
        this.$refs.header.style.position = "absolute";
        this.$refs.header.style.top = "0";
        this.$refs.header.style.left = "0";
        this.$refs.header.style.zIndex = "100";
      }
    },
  },
  mounted() {
    document.body.scrollTop = 0;

    window.addEventListener("scroll", this.scroll, true);
  },
  beforeDestroy() {
    // this.time=null
    window.removeEventListener("scroll", this.scroll, true);
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  background-color: black;
  // overflow: scroll;
  // overflow-x: hidden;
  .toubu {
    width: 1920px;
    height: 500px;
    background-image: url("../../assets/imgs/fangan/bg.png");
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
    .text {
      // min-width: 462px;
      position: absolute;
      top: 180px;
      left: 343px;
      font-size: 60px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 84px;
      color: #ffffff;
      // border-bottom: 1px solid rgba(75, 75, 75, 1);
      // padding-bottom: 20px;
      .bottom {
        font-size: 20px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        line-height: 22px;
        color: #ffffff;
        margin-top: 8px;
      }
    }
  }

  .newbox {
    width: 100%;
    background-color: rgba(27, 27, 27, 1);
    padding: 60px 260px;
    .top {
      width: 100%;
      font-size: 36px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;

      color: #ffffff;
      text-align: center;
    }
    .more {
      width: 100%;
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      color: #999999;
      text-align: center;
      margin-top: 16px;
    }
    .box {
      width: 100%;
      display: flex;
      margin-top: 42px;
      justify-content: space-between;
      .left {
        width: 685px;

        .shang {
          width: 100%;
          height: 538px;
          background: url("../../assets/imgs/fangan/new (3).png");
          background-size: 100% 100%;
          position: relative;
          .title {
            position: absolute;
            top: 444px;
            left: 42px;
            font-size: 30px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 24px;
            color: #ffffff;
          }
          .jieshao {
            width: 620px;
            position: absolute;
            left: 42px;
            top: 490px;
            font-size: 18px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 24px;
            color: #ffffff;
          }
        }
        .xia {
          width: 100%;
          height: 254px;
          background: url("../../assets/imgs/fangan/new (4).png");
          background-size: 100% 100%;
          margin-top: 15px;
          position: relative;
          .title {
            position: absolute;
            bottom: 80px;
            left: 42px;
            font-size: 30px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 24px;
            color: #ffffff;
          }
          .jieshao {
            width: 604px;
            position: absolute;
            left: 42px;
            bottom: 30px;
            font-size: 18px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 24px;
            color: #ffffff;
          }
        }
      }
      .right {
        width: 685px;

        .shang {
          width: 100%;
          height: 254px;
          background: url("../../assets/imgs/fangan/fangan (1).png");
          background-size: 100%;
          border-radius: 4px;
          position: relative;
          .title {
            position: absolute;
            top: 160px;
            left: 42px;
            font-size: 30px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 24px;
            color: #ffffff;
          }
          .jieshao {
            width: 604px;
            position: absolute;
            left: 42px;
            top: 210px;
            font-size: 18px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 24px;
            color: #ffffff;
          }
        }
        .xia {
          width: 100%;
          height: 538px;
          background: url("../../assets/imgs/fangan/new (2).png");
          background-size: 100%;
          border-radius: 4px;
          position: relative;
          margin-top: 15px;

          .title {
            position: absolute;
            bottom: 80px;
            left: 42px;
            font-size: 30px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 24px;
            color: #ffffff;
          }
          .jieshao {
            width: 604px;
            position: absolute;
            left: 42px;
            bottom: 30px;
            font-size: 18px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 24px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
.toback {
  position: fixed;
  top: 50%;
  right: 35px;
  z-index: 10;

  .up {
    margin-top: 20px;
    width: 48px;
    height: 48px;
    background: linear-gradient(180deg, #4470ff 0%, #44b4ff 100%);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    cursor: pointer;
    background-image: url("../../assets/imgs/home/totop.png");
    background-size: 100% 100%;
  }
}
</style>
